import { toast } from "react-toastify";

export const showToastSuccess = (message) => {
  toast.success(message);
};

export const showToastError = (message, statusCode = 0) => {
  if (statusCode !== 401) {
    toast.error(message);
  }
};

export const showToastWarning = (message) => {
  toast.warning(message);
};

export const showToastInfo = (message) => {
  toast.info(message);
};
